<template>
  <HomeFeature icon="/img/icons/ai-feature-brand.svg"
               :overline="$t('home.features.overline.1')"
               :title="$t('home.features.title.1')"
               :description="$t('home.features.desc.1')"
               image="/img/home/transcriber.webp" :image-height="1500" :image-width="2400"
               :image-alt="$t('home.features.img.alt.1')"/>
  <HomeFeature icon="/img/icons/design-brand.svg"
               :overline="$t('home.features.overline.2')"
                :title="$t('home.features.title.2')"
                :description="$t('home.features.desc.2')"
                :link="$t('home.features.link.2')"
                image="/img/home/editor.webp" :image-height="1500" :image-width="2400"
                :image-alt="$t('home.features.img.alt.2')" reverse />
  <HomeFeature  icon="/img/icons/ai-feature-brand.svg"
                :overline="$t('home.features.overline.3')"
                :title="$t('home.features.title.3')"
                :description="$t('home.features.desc.3')"
                :link="$t('home.features.link.3')"
                image="/img/home/analyser.webp" :image-height="1500" :image-width="2400"
                :image-alt="$t('home.features.img.alt.3')"/>
</template>

<script setup>
const { t } = useI18n({
  useScope: 'local'
})
</script>

<style scoped>

</style>
