<template>
  <div class="flex flex-col md:flex-row mx-auto max-w-7xl gap-6 md:gap-8 mb-4 md:mb-12 px-4 xl:px-0">
    <div class="rounded-m bg-grey-100 p-6 md:w-1/2">
      <svg class="w-6 h-6 mb-4" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <mask id="mask0_679_12054" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
          <rect y="0.000488281" width="32" height="32" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_679_12054)">
          <path d="M9.33333 22.6672H18.6667V20.0005H9.33333V22.6672ZM9.33333 17.3338H22.6667V14.6672H9.33333V17.3338ZM9.33333 12.0005H22.6667V9.33382H9.33333V12.0005ZM6.66667 28.0005C5.93333 28.0005 5.30556 27.7394 4.78333 27.2172C4.26111 26.6949 4 26.0672 4 25.3338V6.66716C4 5.93382 4.26111 5.30604 4.78333 4.78382C5.30556 4.2616 5.93333 4.00049 6.66667 4.00049H25.3333C26.0667 4.00049 26.6944 4.2616 27.2167 4.78382C27.7389 5.30604 28 5.93382 28 6.66716V25.3338C28 26.0672 27.7389 26.6949 27.2167 27.2172C26.6944 27.7394 26.0667 28.0005 25.3333 28.0005H6.66667ZM6.66667 25.3338H25.3333V6.66716H6.66667V25.3338Z" fill="#604CDD"/>
        </g>
      </svg>
      <h3 class="font-bold font-poppins text-h4s md:text-h4m mb-4">{{$t('home.extra.title.1')}}</h3>
      <p class="text-sm mb-6 flex-grow md:min-h-[44px]">{{$t('home.extra.desc.1')}}</p>
      <NuxtImg format="webp" class="max-w-xs md:max-w-sm h-auto min-w-full w-full"
                 src="/img/home/content-creation.webp"
                 sizes="100vw sm:500px lg:800px"
                 :width="2400" :height="1500"
                 :alt="$t('home.extra.img.alt.1')" />
    </div>

    <div class="rounded-m bg-grey-100 p-6 md:w-1/2">
      <svg class="w-6 h-6 mb-4" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <mask id="mask0_679_8148" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
          <rect y="0.000488281" width="32" height="32" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_679_8148)">
          <path d="M5.33268 29.3338C4.59935 29.3338 3.97157 29.0727 3.44935 28.5505C2.92713 28.0283 2.66602 27.4005 2.66602 26.6672V5.33382C2.66602 4.60049 2.92713 3.97271 3.44935 3.45049C3.97157 2.92827 4.59935 2.66715 5.33268 2.66715H16.1994L13.5327 5.33382H5.33268V26.6672H19.9993V24.0005H22.666V26.6672C22.666 27.4005 22.4049 28.0283 21.8827 28.5505C21.3605 29.0727 20.7327 29.3338 19.9993 29.3338H5.33268ZM7.99935 24.0005V21.3338H17.3327V24.0005H7.99935ZM7.99935 20.0005V17.3338H14.666V20.0005H7.99935ZM19.9993 20.0005L14.666 14.6672H10.666V8.00049H14.666L19.9993 2.66715V20.0005ZM22.666 15.9338V6.73382C23.466 7.20049 24.1105 7.83382 24.5994 8.63382C25.0882 9.43382 25.3327 10.3338 25.3327 11.3338C25.3327 12.3338 25.0882 13.2338 24.5994 14.0338C24.1105 14.8338 23.466 15.4672 22.666 15.9338ZM22.666 21.6672V18.8672C24.2216 18.3116 25.4993 17.3505 26.4993 15.9838C27.4993 14.6172 27.9993 13.0672 27.9993 11.3338C27.9993 9.60049 27.4993 8.05049 26.4993 6.68382C25.4993 5.31716 24.2216 4.35604 22.666 3.80049V1.00049C24.9771 1.60049 26.8882 2.85049 28.3994 4.75049C29.9105 6.65049 30.666 8.84493 30.666 11.3338C30.666 13.8227 29.9105 16.0172 28.3994 17.9172C26.8882 19.8172 24.9771 21.0672 22.666 21.6672Z" fill="#604CDD"/>
        </g>
      </svg>
      <h3 class="font-bold font-poppins text-h4s md:text-h4m mb-4">{{$t('home.extra.title.2')}}</h3>
      <p class="text-sm mb-6 md:min-h-[44px]">{{$t('home.extra.desc.2')}}</p>
      <NuxtImg format="webp" class="max-w-xs md:max-w-sm h-auto min-w-full w-full"
                 src="/img/home/text-to-speech.webp"
                 sizes="100vw sm:500px lg:800px"
                 :width="2400" :height="1500"
                 :alt="$t('home.extra.img.alt.2')" />
    </div>
  </div>
</template>

<script setup
        lang="ts">

</script>



<style scoped>

</style>