<template>
  <HomeHero />
  <HomeFeatures/>
  <HomeExtra />
  <div class="mx-auto max-w-7xl mb-4 lg:mb-12 px-4 xl:px-0">
    <SignupCta />
  </div>


</template>

<script setup lang="ts">
  import {useUtilsStore} from "~/store/utils";
  import {storeToRefs} from "pinia";
  import {useI18n} from "vue-i18n";
  const {t, locale} = useI18n();

  definePageMeta({
    layout: "default",
  });

  const utilsStore = useUtilsStore();
  const {mailingListDialog} = storeToRefs(utilsStore);
  const openMailingListModal = () => {
      mailingListDialog.value = true;
  }

  let localeExtra = locale.value === 'en-us' ? ' (USA)' : '';

  useHead({
      title: t('home.title') + localeExtra,
      meta: [
          { key: 'og-type', property: 'og:type', content: 'website' },
          {name: 'description', content: t('home.meta.description') + localeExtra},
          { key: 'og-title', property: 'og:title', content: t('home.title') },
          { key: 'og-desc', property: 'og:description', content: t('home.meta.description') },
          { key: 'og-image', property: 'og:image', content: 'https://store.soundmadeseen.com/sms-og-image.jpg' },
          { key: 'og-url', property: 'og:url', content: 'https://soundmadeseen.com' },
          { key: 'og-site-name', property: 'og:site_name', content: 'SoundMadeSeen' },
          { key: 'twitter-card', name: 'twitter:card', content: 'summary_large_image' },
          { key: 'twitter-title', name: 'twitter:title', content: t('home.title') },
          { key: 'twitter-desc', name: 'twitter:description', content: t('home.meta.description') },
          { key: 'twitter-image', name: 'twitter:image', content: 'https://store.soundmadeseen.com/sms-og-image.jpg' },
          { key: 'twitter-site', name: 'twitter:site', content: '@soundmadeseen' },
          { key: 'twitter-creator', name: 'twitter:creator', content: '@soundmadeseen' },
      ],
      link: [
          { rel: 'canonical', href: 'https://soundmadeseen.com' },
          { rel: 'preload', href: '/img/sms-header-small.jpg', as: 'image'}
      ],
  });
</script>

<style scoped>

</style>